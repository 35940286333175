import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/oida-is-des-org-blog/oida-is-des-org-blog/src/templates/blog-post-template.tsx";
import { InlineCode } from '../../components/inline-code/inline-code';
import { PostUpdateTitle } from '../../components/post-update-title/post-update-title';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Native HTML elements are accessible by default and you can style most of them with CSS however you like – as I've shown in
my `}<a parentName="p" {...{
        "href": "https://www.oidaisdes.org/styling-accessible-web-forms.en"
      }}>{`blog post about web forms`}</a>{`.
Then again, there's elements like the file input, which is very hard to style.`}</p>
    <p>{`The `}<InlineCode mdxType="InlineCode">{`<`}{`input type="file"`}{`>`}</InlineCode>{` element is rendered as a button that allows the user to open the
operating system's file picker. This button is completely unstylable – it can't be sized or colored, and it won't even
accept a different font. But don't despair! I'll show you how to make it work.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "66.66666666666666%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAMBBf/EABUBAQEAAAAAAAAAAAAAAAAAAAEA/9oADAMBAAIQAxAAAAGmVDVz0f/EABsQAAEEAwAAAAAAAAAAAAAAAAEAAgMREzFD/9oACAEBAAEFAhIXNzWBqc0uIfY//8QAFREBAQAAAAAAAAAAAAAAAAAAABL/2gAIAQMBAT8BS//EABURAQEAAAAAAAAAAAAAAAAAAAAR/9oACAECAQE/AVf/xAAfEAACAQIHAAAAAAAAAAAAAAAAAQMCEBEhMkFRkcH/2gAIAQEABj8Cbcbw2M4/DV3ZVciP/8QAGxABAAIDAQEAAAAAAAAAAAAAAQARITFBUWH/2gAIAQEAAT8hvCBu7gqrPGKaI+DMctcg5lKc3FVVY4z/2gAMAwEAAgADAAAAECDf/8QAGBEAAgMAAAAAAAAAAAAAAAAAAAERIXH/2gAIAQMBAT8QSmmaP//EABcRAAMBAAAAAAAAAAAAAAAAAAABQXH/2gAIAQIBAT8Qbpk//8QAHRABAQABBAMAAAAAAAAAAAAAAREAITFBYVGB8P/aAAgBAQABPxDZiYQrlQ1nebLkgFPQ+cLCIlhjpzl2LcuBRAU+0yyTRTQ9XP/Z')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "A person is looking for a document in a briefcase.",
          "title": "A person is looking for a document in a briefcase.",
          "src": "/static/832405f6c8080e07c2a951f8c45cdabb/e5166/pexels-anete-lusina-pick-file.jpg",
          "srcSet": ["/static/832405f6c8080e07c2a951f8c45cdabb/f93b5/pexels-anete-lusina-pick-file.jpg 300w", "/static/832405f6c8080e07c2a951f8c45cdabb/b4294/pexels-anete-lusina-pick-file.jpg 600w", "/static/832405f6c8080e07c2a951f8c45cdabb/e5166/pexels-anete-lusina-pick-file.jpg 1200w", "/static/832405f6c8080e07c2a951f8c45cdabb/b17f8/pexels-anete-lusina-pick-file.jpg 1600w"],
          "sizes": "(max-width: 1200px) 100vw, 1200px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span>{`
`}<em parentName="p">{`Photo: © Anete Lusina / pexels.com`}</em></p>
    <h2>{`Step 1: Use Native HTML Elements`}</h2>
    <p>{`When I include a file picker in a web form, I want all users to be able to select and upload files. This includes keyboard
and screen reader users as well. Which is why I'll use the native, accessible `}<InlineCode mdxType="InlineCode">{`<`}{`input type="file"`}{`>`}</InlineCode>{`
element. I've created a demo using the React framework. Here's what my `}<a parentName="p" {...{
        "href": "https://reactjs.org/docs/introducing-jsx.html"
      }}>{`JSX code`}</a>{`
looks like:`}</p>
    <deckgo-highlight-code {...{
      "terminal": "carbon",
      "theme": "dracula"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`<label htmlFor="filepicker" className={styles.filePicker}>
    <span>Upload PDF</span>
    <input
        id="filepicker"
        type="file"
        accept=".pdf"
        aria-describedby="selected-file"
        onChange={event => onFilePickerChange(event)}
    />
</label>
<p id="selected-file">{selectedFile}</p>`}</code>{`
        `}</deckgo-highlight-code>
    <p>{`I use a `}<InlineCode mdxType="InlineCode">{`label`}</InlineCode>{` element that contains the visible label of my file picker ("Upload PDF") as well as
a visually hidden `}<InlineCode mdxType="InlineCode">{`input`}</InlineCode>{` element. This way, the `}<InlineCode mdxType="InlineCode">{`label`}</InlineCode>{` element serves as the
visible UI component and you can apply any custom styling (more on that in step 2).`}</p>
    <p>{`The `}<InlineCode mdxType="InlineCode">{`p`}</InlineCode>{` element displays the hint "No file selected" or, if a file was selected, the file's name
(see step 3). Thanks to the `}<InlineCode mdxType="InlineCode">{`aria-describedby`}</InlineCode>{` attribute, the text is also read by screen readers when
the user arrives at the file input.`}</p>
    <p>{`I also tried setting `}<InlineCode mdxType="InlineCode">{`aria-hidden="true"`}</InlineCode>{` to hide the paragraph itself from assistive technologies. As
this led to problems with certain browser and screen reader combinations, I removed the attribute again. Now screen reader users
may hear the hint twice. Which is better than not hearing it at all.`}</p>
    <h2>{`Step 2: Apply CSS Magic`}</h2>
    <p>{`Next, I use CSS to visually hide the `}<InlineCode mdxType="InlineCode">{`input`}</InlineCode>{` element and position it above the label. This
enables me to style the `}<InlineCode mdxType="InlineCode">{`label`}</InlineCode>{` element and thereby the visible file picker however I want:`}</p>
    <deckgo-highlight-code {...{
      "terminal": "carbon",
      "theme": "dracula"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`form label[for].filePicker {
    position: relative;
    background-color: rgb(49, 4, 92);
    color: white;
    font-size: 1rem;
    // ... more custom styling
}

form label[for].filePicker input[type=file] {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
}`}</code>{`
        `}</deckgo-highlight-code>
    <p>{`It's important that you don't hide the `}<InlineCode mdxType="InlineCode">{`<`}{`input type="file"`}{`>`}</InlineCode>{` from assistive technologies.
Using `}<InlineCode mdxType="InlineCode">{`display: none`}</InlineCode>{` or setting the element's size to zero would hide it from screen readers.`}</p>
    <p>{`To give visual feedback to sighted users, I display an outline on hover and when the `}<InlineCode mdxType="InlineCode">{`input`}</InlineCode>{` element
within the `}<InlineCode mdxType="InlineCode">{`label`}</InlineCode>{` receives focus:`}</p>
    <deckgo-highlight-code {...{
      "terminal": "carbon",
      "theme": "dracula"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`form label[for].filePicker:focus-within,
form label[for].filePicker:hover {
    outline: 2px solid black;
    outline-offset: 2px;
}`}</code>{`
        `}</deckgo-highlight-code>
    <h2>{`Step 3: A Dash of JavaScript`}</h2>
    <p>{`As a last step, I want to display the file name after the user has selected a file. To achieve this, I use
the `}<InlineCode mdxType="InlineCode">{`change`}</InlineCode>{` event, which is fired when an alteration to the input's value is committed by the user.`}</p>
    <deckgo-highlight-code {...{
      "terminal": "carbon",
      "theme": "dracula"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`const FileUpload: React.FunctionComponent = () => {
    const [selectedFile, setSelectedFile] = useState('No file selected');

    const onFilePickerChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = Array.from(event.target.files ?? []);
        if (files.length > 0) {
            setSelectedFile(files[0].name);
        }
    }

    return (
        // Only the relevant sections of the JSX code
        <input type="file"
            // ...
            onChange={event => onFilePickerChange(event)}
        />
        <p id="selected-file">{selectedFile}</p>
        // ...
    );
};`}</code>{`
        `}</deckgo-highlight-code>
    <p>{`Of course, you can do all that in plain JavaScript too – or any framework of your choosing. My code is simply an
example of an implementation as a React component. You can view the complete
`}<a parentName="p" {...{
        "href": "https://github.com/alexlehner86/fancy-css-playground/tree/main/src/pages/FileUpload"
      }}>{`source code on GitHub`}</a>{`.`}</p>
    <h2>{`The Perfect File Input, right?`}</h2>
    <p>{`Check out the result of my styled file input below. I've also included a standard file input for comparison:`}</p>
    <iframe src="https://alexlehner86.github.io/fancy-css-playground/#/fileupload" title="Demo Styled File Input"></iframe>
    <p>{`Awesome, right? I'm happy with the final result. But still, there's room for improvement. The
styled `}<InlineCode mdxType="InlineCode">{`<`}{`input type="file"`}{`>`}</InlineCode>{` works perfectly for sighted keyboard users. For screen reader users,
the results are pretty good, but not perfect.`}</p>
    <p>{`Firefox fixed an `}<a parentName="p" {...{
        "href": "https://github.com/nvaccess/nvda/issues/11742"
      }}>{`important issue`}</a>{` with `}<InlineCode mdxType="InlineCode">{`aria-describedby`}</InlineCode>{`
in version 121. At the moment of my retests, this new version was still in beta. Here's a detailed account of my accessibility
audits on different platforms:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Windows 11, Google Chrome 120.0.6099.71, NVDA 2023.3`}</strong>{`: On focus, NVDA reads “Upload pdf, button, no file selected”. After
selecting the file “testfile.pdf” the browser automatically focuses on the file input and reads “Button, upload pdf, testfile
dot pdf”. When I navigate away and then return, the screen reader announces “Upload pdf, testfile dot pdf, button, testfile
dot pdf”.`}</li>
      <li parentName="ul"><strong parentName="li">{`Windows 11, Firefox Beta 121.0b9, NVDA 2023.3`}</strong>{`: On focus, NVDA reads “Clickable, upload pdf, browse, button, no file
selected”. After selecting the file “testfile.pdf” the browser automatically focuses on the file input and reads “Button,
upload pdf, browse, upload pdf, browse, testfile dot pdf”. When I navigate away and then return, the screen reader announces
“Clickable, upload pdf, browse, button, testfile dot pdf”.`}</li>
      <li parentName="ul"><strong parentName="li">{`Samsung Galaxy S20, Android 13, Google Chrome 119.0.6045.194, TalkBack`}</strong>{`: On focus, TalkBack reads “Upload pdf, no file
chosen, button, no file selected, double tap to activate”. After selecting the file “testfile.pdf” the screen reader announces
the element as “Upload pdf, testfile dot pdf”.`}</li>
      <li parentName="ul"><strong parentName="li">{`Samsung Galaxy S20, Android 13, Firefox for Android Beta 121.0b9, TalkBack`}</strong>{`: The screen reader only focuses on the text
“Upload PDF”. It provides no information about the button role or the file name. At least, the file input can be triggered via
double tap. After selecting the file “testfile.pdf”, this text is displayed next to the button and read by the screen reader on focus.`}</li>
      <li parentName="ul"><strong parentName="li">{`iPhone 8, iOS 16.7.2, Safari, VoiceOver`}</strong>{`: On focus, VoiceOver reads “Upload pdf, button, no file selected”.
After selecting the file “testfile.pdf” the screen reader announces the element as “Upload pdf, button, testfile pdf”.`}</li>
    </ul>
    <h2>{`Conclusion`}</h2>
    <p>{`As my demo shows, you can create an accessible file picker with custom styling using
the `}<InlineCode mdxType="InlineCode">{`<`}{`input type="file"`}{`>`}</InlineCode>{` element and a bit of ingenuity.`}</p>
    <p>{`Unfortunately, I've encountered some accessibility problems with certain browser and screen reader combinations. Maybe this
will be fixed in future updates of the browsers and/or screen readers. Or maybe I'll discover a better solution. We'll see.`}</p>
    <PostUpdateTitle mdxType="PostUpdateTitle">Update on 03/26/2023</PostUpdateTitle>
    <p>{`I was wrong: There actually is a way to customize the native `}<InlineCode mdxType="InlineCode">{`<`}{`input type="file"`}{`>`}</InlineCode>{` HTML element.
You can use the `}<InlineCode mdxType="InlineCode">{`::file-selector-button`}</InlineCode>{` CSS pseudo-element to style the rendered button.
Unfortunately, you can't change the text rendered inside the button.
Here's a `}<a parentName="p" {...{
        "href": "https://css-tricks.com/almanac/selectors/f/file-selector-button/"
      }}>{`demo with more info`}</a>{` about it.`}</p>
    <PostUpdateTitle mdxType="PostUpdateTitle">Update on 12/10/2023</PostUpdateTitle>
    <p>{`I revised the HTML code in my example and redid the screen reader tests with the current browser versions.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      